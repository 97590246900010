.incident-info-container {
    // dl {
    //     margin-bottom:50px;
    // }

    dl dt {
        float       : left;
        font-weight : 400;
        margin-right: 10px;
        padding     : 5px;
        width       : 230px;
        font-size   : 14px;
        color       : #212529;
    }

    dl dd,
    dl .correlated-link {
        display    : table;
        margin     : 2px 0;
        padding    : 5px 0;
        font-weight: 400;
        color      : rgb(112, 112, 112);
    }

    dl .correlated-link {
        color          : #51bcda;
        text-decoration: none;
        font-size      : 1.2em;
        
        &:hover {
            color: #51bcda;
        }
    }

    .markdown-label {
        strong {
            font-weight: normal;
        }
    }

    // ----------------------------    Animated link underline
    a.animated-link,
    a.animated-link:visited,
    a.animated-link:hover,
    a.animated-link:active {
        cursor                     : pointer;
        -webkit-backface-visibility: hidden;
        backface-visibility        : hidden;
        position                   : relative;
        transition                 : 0.2s color ease;
        text-decoration            : none;
        color                      : #51bcda;
    }

    a.animated-link.after:after {
        content                    : "";
        transition                 : 0.2s all ease;
        -webkit-backface-visibility: hidden;
        backface-visibility        : hidden;
        position                   : absolute;
        bottom: 2px;
        height    : 2px;
        width     : 0;
        background: #51bcda;
    }

    a.animated-link:after {
        left             : 50%;
        -webkit-transform: translateX(-50%);
        transform        : translateX(-50%);
    }

    a.animated-link.after:hover:after {
        width: 100%;
    }

    // ----------------------------    End Animated link underline

}