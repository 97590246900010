
form.search-form {    
    .input-group > input {
        width: 180px;
        transition: width 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }

      .input-group > input:focus, .input-group > input.text-present {
        width: 220px;
        border: medium none;
        background-color: rgba(222, 222, 222, 0.5);
    }
      .input-group > input.form-control {
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        padding-left: 24px;
    }

    .input-group.no-border > .input-group-append .input-group-text {
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        padding-left: 10px;
        padding-right: 14px;
        cursor: pointer;
    }

    .input-group.no-border > .input-group-append .input-group-text.text-present {
        background-color: rgba(222, 222, 222, 0.5);
    }
}  

.fa-rotate-90 {
    transform: rotate(90deg) !important;
    top: 20% !important;
    left: 20% !important;
}