.visible-content-animated-start {
    opacity: 1;
    height : 100%;
}

.visible-content-animated-start.hidden-content-animated-end {
    opacity   : 0;
    height    : 0;
    transition: opacity 0.1s ease-in-out 0.8s, height 0.1s ease-in-out 0.8s;
}

.visible-translated-content-animated-start {
    opacity  : 0;
    transform: translateY(2%);
}

.visible-translated-content-animated-start.hidden-translated-content-animated-end {
    opacity   : 1;
    transform : translateY(0%);
    transition: opacity 0.3s ease-in-out 0.8s, transform 0.3s ease-in-out 0.8s;
}

.page-title-with-corner-container {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin-bottom  : 20px;

    .actions-btns {
        display       : flex;
        align-items   : center;
        flex-direction: row;

        .btn {
            display        : flex;
            flex-direction : row;
            justify-content: center;
            align-items    : center;
            padding-top    : 0;
            padding-bottom : 0;

            &.usage-action {
                font-size: 24px;
                margin   : 0;

                &:hover,
                &:focus,
                &:active {
                    background-color: transparent !important;
                    color           : #2ba9cd !important;
                    box-shadow      : none !important;
                }
            }

            &.details-call-to-action {
                display       : flex;
                flex-direction: row;
                align-items   : center;
                margin-top    : 0px;
                margin-bottom : 0px;
                box-shadow    : 0 6px 10px -4px rgba(0, 0, 0, 0.15);
                font-size     : 13px;
                letter-spacing: 0.4px;
                border-radius : 28px;
                padding-top   : 12px;
                padding-bottom: 12px;
                height        : 44px;

                i {
                    margin-right: 17px;
                    font-size   : 16px;
                }
            }

            &.edit-action {
                font-size    : 28px;
                padding-left : 8px;
                padding-right: 16px;
                margin-left  : 30px;
            }
        }
    }

    .btn.btn-info.details-call-to-action,
    .btn.btn-info.details-call-to-action:active,
    .btn.btn-info.details-call-to-action:focus,
    .btn.btn-info.details-call-to-action:hover {
        box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15) !important;
    }

    .btn.btn-info.details-call-to-action:active,
    .btn.btn-info.details-call-to-action:focus {
        background-color: $primary-color !important;
    }
}

.page-title-container {
    display       : flex;
    flex-direction: row;

    h4,
    h5 {
        margin-top   : 0;
        padding-right: 18px;
        padding-left : 18px;
        margin-bottom: 0;
    }

    &.client-contacted-info {
        justify-content: center;
        align-items    : center;

        h5 {
            font-weight: 400;
            font-size  : 20px;
        }

        h6.additional-info {
            font-weight   : normal;
            font-size     : 14px;
            color         : #2c2c2c;
            font-style    : oblique;
            padding       : 0;
            padding-bottom: 2px;
            padding-top   : 10px;
            border-bottom : 1px solid #707070;
            margin-left   : 12px;
        }
    }
}

.back-container {
    display       : flex;
    flex-direction: row;
    align-items   : center;

    button {
        font-weight  : 500;
        margin-top   : 0;
        margin-bottom: 0px;
        padding-right: 18px;
        padding-left : 18px;
        box-shadow   : 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    }

    button.btn.btn-icon {
        height: 44px;
        width : 44px;
    }

    i {
        font-size   : 22px;
        margin-right: 10px;
    }
}

.tab-buttons {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    margin-bottom  : 28px;

    button {
        display       : flex;
        flex-direction: row;
        align-items   : center;
        margin-top    : 0;
        margin-bottom : 0;
        height        : 38px;
    }

    button.tab-header-btn:hover,
    button.tab-header-btn:focus,
    button.tab-header-btn:active {
        background-color: #51bcda !important;
    }

    button.tab-header-btn:hover {
        opacity: 0.7;
    }

    button.tab-item-active,
    button.tab-item-active:hover {
        background-color: #51bcda;
        opacity         : 1;
        color           : #FFFFFF;
    }

    i {
        margin-right: 12px;
    }
}

.tab-buttons button:first-child {
    margin-right: 30px;
}

.content.communication-details {
    .tab-item-content {
        display   : block;
        height    : 0;
        opacity   : 0;
        transition: height 0.25 linear 0ms, opacity 0.25 linear 0ms;
    }

    .tab-item-content.tab-item-active {
        opacity   : 1;
        height    : 100%;
        transition: height 0.25 linear 0ms, opacity 0.25 linear 0ms;
    }

    .timeline-section {
        .card.card-timeline {
            margin-bottom: 0;

            &>.card-body {
                padding-bottom: 0;
                max-height    : calc(100vh - 170px);
                height        : auto;

                .timeline {
                    margin-bottom: 0;
                }
            }
        }

        .shadow-bottom {
            margin: 0;
            height: 8px;
        }
    }

}

.card.details-list-card {
    // max-height: calc(100vh - 190px);
    // height: calc(100vh - 190px);
    padding: 0px 10px 10px 10px;

    .card-header {
        h4 {
            margin-top   : 0px;
            margin-bottom: 0px;
            font-weight  : 400;
            font-size    : 20px;
        }
    }


}

.details-left-side {
    padding-left : 25px;
    padding-right: 14px;
    padding-top  : 0px;
}

.page-h-centered {
    position : fixed;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
    .content.communication-details {
        .timeline-section {
            .card.card-timeline {

                &>.card-body {
                    max-height: calc(100vh - 180px);
                }
            }
        }
    }
}

@media (max-width: 812px) {
    .communication-details .col-md-6 {
        flex     : 0 0 100%;
        max-width: 100%;
    }

    .communication-details .card.card-timeline>.card-body {
        height    : unset;
        max-height: unset;
    }

    .visible-translated-content-animated-start.hidden-translated-content-animated-end {
        .col-md-6 {
            margin-bottom: 32px;
        }
    }
}


@media (max-width: 620px) {
    .wrapper .main-panel>.communication-details {
        padding-right: 0;
    }

    .wrapper .main-panel>.communication-details.content {
        margin-left : 0;
        margin-right: 0;
    }

    .details-header.page-title-with-corner-container {
        flex-direction: column;
        align-items   : flex-start;
    }

    .details-header.page-title-with-corner-container .page-title-container.client-contacted-info {
        margin-bottom: 16px;
    }

    .details-header.page-title-with-corner-container .page-title-container.client-contacted-info h5 {
        font-size: 18px;
        min-width: 180px;
        padding  : 0;
    }

}