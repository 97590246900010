.nav-item {
    * {
        outline: none;
    }

    .react-select-custom {
        margin-right: 16px;
        cursor: pointer;
        min-width: 180px;
        top: 2px;

        &.css-2b097c-container {
            min-width: 180px;
            cursor: pointer;
            top: 2px;
        }
        
        .css-yk16xz-control {
            cursor: pointer;
            border: $primary-color;
        }
        .css-1pahdxg-control {
            box-shadow: none;
            border: none;
        }

        .css-1pahdxg-control:hover, .css-1pahdxg-control:active, .css-1pahdxg-control:focus {
            border: none;
        }

        // .css-26l3qy-menu {
        div[class*="-menu"] {
            //cursor: pointer;

            div[class*="-MenuList"] {
                //cursor: pointer;

                div[class*="-option"] {
                    cursor: pointer;
                }

                .css-9gakcf-option {
                    background-color: $primary-color;
                }

                .css-yt9ioa-option, .css-1n7v3ny-option {
                   background-color: transparent;
                }

                .css-yt9ioa-option:hover, .css-1n7v3ny-option:hover {
                    background-color: #DEEBFF;
                 }
            }
        }
     
    }
}