.shadow-top {
    position: relative;
    z-index : 2;

    box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.15) inset;

    height       : 20px;
    margin-bottom: -20px;
    margin-right : 10px;
}

.shadow-bottom {
    position: relative;
    z-index : 2;

    box-shadow: 0px -10px 10px -10px rgba(0, 0, 0, 0.15) inset;

    height      : 20px;
    margin-top  : -35px;
    margin-right: 10px;
}

.scrollingClass {
    scroll-behavior: smooth;
}

.card.card-timeline {

    >.card-body {
        // max-height: 70vh;
        max-height   : calc(100vh - 190px);
        height       : calc(100vh - 190px);
        position     : relative;
        overflow-y   : auto;
        padding-right: 20px;
    }

    >.card-body>.timeline:before {
        top             : 20px;
        bottom          : 35px;
        position        : absolute;
        content         : " ";
        width           : 3px;
        background-color: #dbdbdb;
        left            : 50%;
        margin-left     : -1.5px;
    }

    .timeline:before {
        content: none;
    }

    .timeline {
        list-style: none;
        padding   : 0px;
        position  : relative;
        margin-top: 0px;


        .timeline-panel {
            .operator-icon-container {
                color           : #FFF;
                background-color: #66615bab;
                position        : absolute;
                top             : 10px;
                right           : 10px;
                border          : 1.5px solid currentColor;
                padding         : 8px;
                border-radius   : 50%;
                width           : 40px;
                height          : 40px;
                display         : flex;
                justify-content : center;
                align-items     : center;

                i.fa {
                    font-size: 14px;
                }
            }
        }

        .timeline-footer {
            .btn {
                margin: 0;
            }
        }

        h6 {
            color      : $dark-gray;
            font-weight: 400;
            margin     : 10px 0px 0px;
        }

        &.timeline-simple {

            &:before {
                left: 5%;
            }

            >li>.timeline-panel {
                width: 86%;
            }


            >li>.timeline-badge {
                left: 5%;
            }
        }

        >li {
            margin-bottom: 5px;
            position     : relative;

            &:before,
            &:after {
                content: " ";
                display: table;
            }

            &:after {
                clear: both;
            }

        }

        >li>.timeline-panel {
            width           : 41%;
            float           : left;
            padding         : 16px;
            border-radius   : $border-radius-small;
            box-shadow      : 0 2px 2px rgba(204, 197, 185, 0.5);
            background-color: $white-color;
            color           : $black-color;
            margin-bottom   : 20px;
            position        : relative;

            &:before {
                position     : absolute;
                top          : 26px;
                right        : -15px;
                display      : inline-block;
                border-top   : 15px solid transparent;
                border-left  : 15px solid $light-gray;
                border-right : 0 solid $light-gray;
                border-bottom: 15px solid transparent;
                content      : " ";
            }

            &:after {
                position     : absolute;
                top          : 27px;
                right        : -14px;
                display      : inline-block;
                border-top   : 14px solid transparent;
                border-left  : 14px solid $white-color;
                border-right : 0 solid $white-color;
                border-bottom: 14px solid transparent;
                content      : " ";
            }
        }



        >li>.timeline-badge {
            color                     : $white-color;
            width                     : 50px;
            height                    : 50px;
            text-align                : center;
            position                  : absolute;
            top                       : 16px;
            left                      : 50%;
            margin-left               : -25px;
            background-color          : $dark-gray;
            z-index                   : 100;
            border-top-right-radius   : 50%;
            border-top-left-radius    : 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius : 50%;
            border                    : 3px solid #f4f3ef;
            box-shadow                : 0 6px 10px -4px rgba(0, 0, 0, 0.15);

            [class^="fa-"],
            [class*=" fa-"] {
                line-height: inherit;
            }

            .nc-icon {
                line-height: 2.1;
                width      : 25px;
                text-align : center;
                font-size  : 21px;
            }

            &.start {
                width      : 20px;
                margin-left: -9px;
                height     : 20px;
                top        : 30px;
            }

        }

        >li.timeline-inverted>.timeline-panel {
            float           : right;
            background-color: #fff;

            &:before {
                border-left-width : 0;
                border-right-width: 15px;
                left              : -15px;
                right             : auto;
            }

            &:after {
                border-left-width : 0;
                border-right-width: 14px;
                left              : -14px;
                right             : auto;
            }

            &.start {

                &:before {
                    top               : 26px;
                    border-left-width : 0;
                    border-right-width: 15px;
                    left              : -15px;
                    right             : auto;
                }

                &:after {
                    border-left-width : 0;
                    border-right-width: 14px;
                    top               : 27px;
                    left              : -14px;
                    right             : auto;
                }
            }

        }
    }

    .timeline-heading {
        margin-bottom: 15px;

        .badge {
            color         : $white-color;
            padding       : 4px 8px;
            text-transform: uppercase;
            font-size     : .7142em;
            line-height   : 14px;
            letter-spacing: 0.5px;
            width: 100%;
            white-space: normal;

            &.badge-default {
                border-color    : #9A9A9A;
                background-color: #9A9A9A;
            }
        }
    }

    .timeline-badge.primary {
        background-color: $primary-color !important;
    }

    .timeline-badge.info {
        background-color: $info-color !important;
    }

    .timeline-badge.success {
        background-color: $success-color !important;
    }

    .timeline-badge.warning {
        background-color: $warning-color !important;
    }

    .timeline-badge.danger {
        background-color: $danger-color !important;
    }

    .timeline-badge.extra-info {
        background-color: $purple-color !important;
    }

    .timeline-title {
        margin-top: 0;
        color     : inherit;
    }

    .timeline-body>p,
    .timeline-body>ul {
        margin-bottom: 0;
    }

    .timeline-body>p+p {
        margin-top: 5px;
    }
}

.ps__rail-y,
.ps__rail-x {
    opacity: 0.6 !important;
}

.ps__rail-x {
    display: none !important;
}