.ReactTable.v8 {
    transition: width 0.3s, left 0.3s, padding-right 0.3s;
    width: 100%;
    padding-right: 0px;
    position: relative;

    .loader-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #FFF, $alpha: 0.8);
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;

        .loading-msg {
            color: #252422;
            font-size: 15px;
            animation: loadingEnter 0.2s linear;
        }
    }

    table.table-v8 {
        border: 1px solid #dee2e6;

        thead {
            tr {
                th {
                    transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
                    padding: 5px;
                    position: relative;
                    text-align: left;
                    overflow: hidden;
                    white-space: nowrap;
                    border: 0;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 2.5em !important;

                    .header-content {
                        display: flex;
                        justify-content: space-between;
                        padding-left: 8px;

                        &.cursor-pointer {
                            cursor: pointer;
                        }

                        .header-title {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .header-sorting-item {}
                    }

                    &.document-dl-col {
                        display: none;
                    }
                }
            }
        }

        tbody {
            overflow: hidden;

            tr {
                &.is-even {
                    background-color: rgba(0, 0, 0, 0.03);
                }

                &.is-last-seen {
                    color: #2ba9cd;
                    font-weight: 700;
                }

                td {
                    border-right: 1px solid #dee2e6;
                    border-top: 1px solid #dee2e6;
                    cursor: pointer;
                    font-size: 14px;
                    line-height: 2;
                    padding: 6px 7px;
                    transition: .3s ease;
                    transition-property: width, min-width, padding, opacity;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 190px;

                    &.communication-statuses .statuses-list {
                        align-items: center;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            background-color: #e6e6e6;
                            border-radius: 4px;
                            display: inline-block;
                            font-size: 10px;
                            font-weight: 600;
                            letter-spacing: .5px;
                            margin-left: 10px;
                            margin-top: 6px;
                            padding: 0 6px;
                            text-transform: uppercase;

                            &:first-child {
                                margin-left: 0;
                            }
                        }

                        &.interaction-list li {
                            font-size: 13px;
                            margin-left: 16px;
                            padding: 0 10px;
                        }
                    }

                    &.document-dl {
                        display: none;
                    }

                    &.correlated-crash {

                        .linked-crash {
                            display: inline-block;
                            font-weight: bold;

                            i {
                                transform: rotate(-90deg);
                                transition: transform 0.2s;
                            }
                        }
                    }
                }

                &.is-expanded {
                    td {
                        .linked-crash {
                            i {
                                transform: rotate(0);
                            }
                        }
                    }
                }
            }



            .correlated-container {
                min-height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px 4px;

                .grid-title {
                    align-self: flex-start;
                    font-weight: bold;
                    border-bottom: 1px solid currentColor;
                }

                .correlated-body {
                    padding: 15px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    width: 100%;

                    .grid-container {
                        display: grid;
                        grid-gap: 20px;
                        grid-template-columns: repeat(3, 1fr);
                        grid-template-rows: repeat(2, 1fr);
                    }

                    dt {
                        display: inline-block;
                        margin: 0;
                    }

                    dd {
                        display: inline-block;
                        margin: 0;
                        margin-left: 10px;
                    }

                    .go-to-details {
                        .btn {
                            padding-top: 8px;
                            padding-bottom: 8px;
                            letter-spacing: 0.5px;
                            padding-right: 18px;
                            min-width: 150px;

                            span {
                                display: inline-block;
                                margin-left: 8px;
                                padding-top: 2px;
                            }
                        }
                    }
                }

                &.is-loading {
                    justify-content: center;

                    .spinner {
                        margin: 0 auto 0;
                        width: 70px;
                        text-align: center;
                    }

                    .spinner>div {
                        width: 11px;
                        height: 11px;
                        background-color: rgba(37, 36, 34, 0.7);
                        margin-right: 8px;

                        border-radius: 100%;
                        display: inline-block;
                        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                    }

                    .spinner .bounce1 {
                        -webkit-animation-delay: -0.32s;
                        animation-delay: -0.32s;
                    }

                    .spinner .bounce2 {
                        -webkit-animation-delay: -0.16s;
                        animation-delay: -0.16s;
                    }

                    @-webkit-keyframes sk-bouncedelay {

                        0%,
                        80%,
                        100% {
                            -webkit-transform: scale(0)
                        }

                        40% {
                            -webkit-transform: scale(1.0)
                        }
                    }

                    @keyframes sk-bouncedelay {

                        0%,
                        80%,
                        100% {
                            -webkit-transform: scale(0);
                            transform: scale(0);
                        }

                        40% {
                            -webkit-transform: scale(1.0);
                            transform: scale(1.0);
                        }
                    }

                }
            }
        }
    }
}

@keyframes loadingEnter {
    0% {
        transform: translateY(-5px)
    }

    100% {
        transform: translateY(0)
    }
}