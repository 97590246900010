.wrapper{
    .sidebar-wrapper {
        li.hidden {
            display: none;
        }
    }

    .sidebar {
        display: none;
    }

    .main-panel {
        width: 100%;

        > .content {
            max-width: 95%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 88px;
            overflow-y: hidden;
        }
    }
}


@media screen and (min-width: 1600px){
    .wrapper{
        .main-panel {
            > .content {
                max-width: 94%;
            }
        }
    }
}


@media screen and (min-width: 1800px){
    .wrapper{
        .main-panel {
            > .content {
                max-width: 93%;
            }
        }
    }
}


