.wrapper-full-page {
    position       : relative;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;

    &:after {
        content         : "";
        background-color: rgba(0, 0, 0, .4);
        position        : absolute;
        z-index         : 1;
        height          : 100%;
        width           : 100%;
        display         : block;
        top             : 0;
        left            : 0;
    }

    .login-page {
        z-index  : 2;
        width    : 100%;
        position : absolute;
        bottom   : 50%;
        right    : 50%;
        transform: translate(50%, 50%);

        .card-header {
            margin-bottom: 8px;

            .header-container {
                display        : flex;
                justify-content: center;
                align-items    : center;
                margin-bottom  : 25px;

                .logo-img {
                    padding-right: 25px;
                    padding-left : 25px;
                }

                .header {
                    margin-bottom: 0;
                }
            }


            .text-description {
                margin-bottom: 0;
                line-height  : 23px;
                text-align   : justify;
            }
        }

        .card-body {
            padding-bottom: 0;
            padding-left  : 20px;
            padding-right : 20px;
            margin-bottom : 0;

            .input-group,
            .form-group {
                margin-bottom: 25px;
                position     : relative;
            }

            .input-group:last-child {
                margin-bottom: 10px;
            }

            .input-group:focus-within {
                .input-group-text {
                    border      : 1px solid #9A9A9A;
                }
            }
        }

        .card-footer {
            padding-top: 0;
            z-index    : 1;
        }

        .error-msg-info {
            padding   : 0 15px;
            color     : #eb6532;
            font-style: oblique;
            text-align: center;
            visibility: hidden;
            z-index   : 1;
            position  : relative;

            p {
                margin-bottom: 8px;
            }

            &.element-shown {
                visibility: visible;
            }
        }

        .loader-container {
            position        : absolute;
            top             : 0;
            right           : 0;
            bottom          : 0;
            left            : 0;
            width           : 100%;
            display         : flex;
            justify-content : center;
            align-items     : center;
            background-color: #FFF;
            border-radius   : 12px;
            z-index         : 4;
            opacity         : 0;
            height          : 0%;

            &.loading-visible {
                opacity: 0.8;
                height : 100%;
            }
        }
    }

}