.dropup .dropdown-toggle:after, 
.dropdown .dropdown-toggle:after,
.nav-menu .dropdown-toggle:after {
    transform: rotate(0);
}

.dropup .dropdown-toggle.activated:after, 
.dropdown .dropdown-toggle.activated:after,
.nav-menu .dropdown-toggle.activated:after
 {
    transform: rotate(180deg);
}

.dropup .dropdown-toggle.activated i, 
.dropdown .dropdown-toggle.activated i,
.nav-menu .dropdown-toggle.activated i
 {
    transform: rotate(20deg);
}
 
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):focus,  
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):active,
.navbar.navbar-dark .nav-menu .nav-link:not(.btn):active
 {
    color: $font-color;
}


.navbar.navbar-transparent .nav-item .nav-link:not(.btn).activated ,
.nav-menu .nav-link:not(.btn).activated,
.navbar.navbar-dark .nav-menu .nav-link:not(.btn):hover,
.navbar.navbar-dark .nav-menu .nav-link:not(.btn).activated
{
    color: $primary-color;
}
