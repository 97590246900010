.password-recovery-container {
    width: 80%;
    z-index: 2;
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    color: #252422;
    padding: 40px;

    @media (min-width: 992px) {
        width: 45%;
        max-width: 800px;
    }

    .password-recovery-form-container {
        display: flex;
        flex-direction: row;
        gap: 2.5em;

        @media (max-width: 576px) {
            flex-direction: column;
        }
    }

    .left-side {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px;

        .input-container {
            position: relative; 
        }

        .form-control {
            padding-right: 40px;
        }
    }

    .right-side {
        padding: 5px;

        li {
            margin: 1rem 0;
        }
    }

    ul {
        @media (max-width: 576px) {
            padding-left: 1rem;
        }
       
    }

    .footer {
        @media (max-width: 576px) {
            text-align: center;
        }
    }
}

