.otp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;

    @media screen and (max-width: 768px) {
        gap: 5px;
    }

    input {
        width: 45px;
        height: 45px;
        border: 1px solid lightgray;
        text-align: center;
        font-weight: bolder;
        font-size: medium;
        
        &.light-border {
            border: 1px solid lightgray;
            border-radius: 10px;
        }

        &.red-border {
            border: 1px solid red;
            border-radius: 10px;
        }
    }

    p {
        text-align: center;
    }

    
}

.otp-error {
    text-align: center;
    color: red;
}