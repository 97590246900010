.change-pwd-container {
    width: 80%;
    z-index: 2;
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    color: #252422;
    padding: 16px;
    
    @media (min-width: 992px) {
        width: 45%;
        padding: 40px;
    }

    .change-pwd-form-container {
        display: flex;
        flex-direction: row;
        gap: 2.5em;

        @media (max-width: 576px) {
            flex-direction: column;
        }
    }

    .left-side {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px;
        gap: 10px;

        .input-container {
            position: relative; 
        }

        input {
            &.error {
                border: 1px solid red;
            }
        }

        .form-control {
            padding-right: 40px;
        }
    }

    .right-side {
        padding: 5px;
    }

    .show-hide-password {
        animation-duration: 0.75s;
        animation-name: fadeIn;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &.error {
            color: red;
        }
    }

    ul {
        @media (max-width: 576px) {
            padding-left: 1rem;
        }
       
    }

    .footer {
        @media (max-width: 576px) {
            text-align: center;
        }
    }
}
