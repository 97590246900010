.mt1 {
    margin-top: 1em;
}

.mt2 {
    margin-top: 2em;
}

.mb1 {
    margin-bottom: 1em;
}

.mb2 {
    margin-bottom: 2em;
}

