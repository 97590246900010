.communications-page {
    .comm-list-container {
        position: relative;
        overflow: hidden;

        > div {
            width: 100%;
            transition: width 0.3s, left 0.3s, padding-right 0.3s;
        }

        .map-container {
            width: 34%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;

            .map-item {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                > div {
                    max-height: 100%;
                }
            }
        }

        &.map-active {
            .ReactTable {
                width: 66%;
                padding-right: 20px;
            }

            .map-container {
                left: 66%;
            }
        }
    }

    .ReactTable {
        padding-right: 0;

        .rt-thead {
            .rt-resizable-header-content {
                padding-left: 8px;
            }

            .rt-th.-cursor-pointer > div:first-of-type:after {
                opacity: 0.6;
                position: absolute;
                display: inline-block;
                bottom: unset;
                font-family: $icon-font-family;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                padding-right: 10px;
            }
        }

        .rt-table {
            overflow: hidden;

            .rt-tbody {
                overflow: hidden;
            }

            .correlated-container {
                min-height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px;

                .grid-title {
                    align-self: flex-start;
                    font-weight: bold;
                    border-bottom: 1px solid currentColor;
                }

                .correlated-body {
                    padding: 15px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    width: 100%;

                    .grid-container {
                        display: grid;
                        grid-gap: 20px;
                        grid-template-columns: repeat(3, 1fr);
                        grid-template-rows: repeat(2, 1fr);
                    }

                    dt {
                        display: inline-block;
                        margin: 0;
                    }

                    dd {
                        display: inline-block;
                        margin: 0;
                        margin-left: 10px;
                    }

                    .go-to-details {
                        .btn {
                            padding-top: 8px;
                            padding-bottom: 8px;
                            letter-spacing: 0.5px;
                            padding-right: 18px;
                            min-width: 150px;

                            span {
                                display: inline-block;
                                margin-left: 8px;
                                padding-top: 2px;
                            }
                        }
                    }
                }

                &.is-loading {
                    justify-content: center;

                    .spinner {
                        margin: 0 auto 0;
                        width: 70px;
                        text-align: center;
                    }

                    .spinner > div {
                        width: 11px;
                        height: 11px;
                        background-color: rgba(37, 36, 34, 0.7);
                        margin-right: 8px;

                        border-radius: 100%;
                        display: inline-block;
                        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                    }

                    .spinner .bounce1 {
                        -webkit-animation-delay: -0.32s;
                        animation-delay: -0.32s;
                    }

                    .spinner .bounce2 {
                        -webkit-animation-delay: -0.16s;
                        animation-delay: -0.16s;
                    }

                    @-webkit-keyframes sk-bouncedelay {
                        0%,
                        80%,
                        100% {
                            -webkit-transform: scale(0);
                        }

                        40% {
                            -webkit-transform: scale(1);
                        }
                    }

                    @keyframes sk-bouncedelay {
                        0%,
                        80%,
                        100% {
                            -webkit-transform: scale(0);
                            transform: scale(0);
                        }

                        40% {
                            -webkit-transform: scale(1);
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        .rt-thead .rt-th.correlated-crash,
        .rt-table .rt-td.correlated-crash {
            > div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            .linked-crash {
                display: inline-block;
                font-weight: bold;

                i {
                    transform: rotate(-90deg);
                    transition: transform 0.2s;
                }

                &.is-expanded {
                    i {
                        transform: rotate(0);
                    }
                }
            }
        }

        .rt-thead .rt-th.template-type-col,
        .rt-table .rt-td.template-type {
            flex: 150 0 auto !important;
        }

        .rt-thead .rt-th.client-contacted-col,
        .rt-table .rt-td.client-contacted-col,
        .rt-thead .rt-th.comm-channel-col,
        .rt-table .rt-td.comm-channel {
            flex: 80 0 auto !important;
        }

        .rt-thead .rt-th.communication-statuses-col,
        .rt-table .rt-td.communication-statuses {
            flex: 150 0 auto !important;
        }

        .rt-table .rt-td.communication-statuses {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 6px;

            .statuses-list {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                align-items: center;

                li {
                    display: inline-block;
                    margin-left: 10px;
                    padding: 0px 6px;
                    background-color: #e6e6e6;
                    border-radius: 4px;
                    margin-top: 6px;
                    font-size: 10px;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    font-weight: 600;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                &.interaction-list {
                    li {
                        margin-left: 16px;
                        padding: 0px 10px;
                        font-size: 13px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .rt-tr.is-last-seen {
            color: #2ba9cd;
            font-weight: bold;
        }

        .-pagination {
            input {
                max-width: 90px;
            }

            select {
                max-width: 110px;
            }
        }
    }

    .map-container {
        display: flex;
        flex-direction: column;
        min-height: 513px;

        .zoom-container {
            position: absolute;
            top: 15px;
            right: 15px;
            user-select: none;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
            border-radius: 2px;
            cursor: pointer;
            background-color: rgb(255, 255, 255);
            width: 40px;
            height: 81px;

            .gm-control-active {
                background: none;
                display: block;
                border: 0px;
                margin: 0px;
                padding: 0px;
                position: relative;
                cursor: pointer;
                user-select: none;
                overflow: hidden;
                width: 40px;
                height: 40px;
                top: 0px;
                left: 0px;
                outline: none;

                i {
                    color: #666666;
                }

                &:hover {
                    i {
                        color: #252422;
                    }
                }
            }

            .divider {
                position: relative;
                overflow: hidden;
                width: 30px;
                height: 1px;
                margin: 0px 5px;
                background-color: rgb(230, 230, 230);
                top: 0px;
            }
        }

        .map-pin {
            width: 18px;
            height: 22px;
            background: url("./img/map-pin.png") no-repeat center;
            background-size: 17px 21px;
            transition: 0.2s;
        }
    }

    &.content {
        .card-title {
            margin-top: 4px;
            margin-bottom: 4px;
        }
    }

    .client-contacted-i-container {
        height: 100%;
        color: #6bd098;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        i.far {
            font-size: 1.8em;
        }
    }

    .comm-navigations-list-container {
        z-index: 2;
        position: fixed;
        top: 125px;
        left: 0px;

        ul {
            list-style: none;

            li {
                position: relative;
                color: #c0c0c0;
                font-size: 26px;
                width: 55px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 29px;
                border-radius: 50%;

                &:hover {
                    cursor: pointer;
                    color: #bec5cf;
                }

                &.active {
                    color: #51bcda;
                    background: #fff;
                    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

                    &::after {
                        content: "";
                        position: absolute;
                        z-index: 9999;
                        top: 50%;
                        right: -51px;
                        transform: translateY(-50%);
                        width: 0;
                        height: 0;
                        border-top: 30px solid transparent;
                        border-right: 30px solid #fff;
                        border-bottom: 30px solid transparent;
                        // box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
                    }
                }

                &:last-child {
                    margin-top: 30px;
                }
            }
        }
    }
}

.card-header.header-with-search,
.card-header.header-with-corner-button,
.card-header.header-center {
    display: flex;
    flex-direction: row;
}

.card-header.header-with-search,
.card-header.header-with-corner-button {
    justify-content: space-between;

    .header-items-container {
        display: flex;
        align-items: center;

        .download-all-list {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            margin-right: 30px;
            cursor: pointer;
            transition: 0.3s;
            font-size: 14px;
            font-weight: bolder;
            color: #2ba9cd;

            &:hover {
                color: #51bcda;
            }

            .text-container {
                margin-left: 6px;
                letter-spacing: 0.3px;
            }

            .nc-icon {
                font-weight: bolder;
                font-size: 20px;
            }

            .div-container-icon {
                margin-right: 6px;
            }

            .downloading-warning-container {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                padding-left: 24px;
                top: 0;
                right: 0;
                left: 0;
                height: 0;
                // bottom          : 0;
                background-color: #fff;
                color: #2ba9cd;
                transition: 0s;

                &.is-downloading-visible {
                    opacity: 1;
                    height: 100%;
                    visibility: visible;
                    transition: 0.3s;
                }
            }
        }
        flex-direction: row;

        .btn-filter {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            cursor: pointer;
            margin: 0 16px 0px 0px;
            padding: 0 12px;
            height: 37px;
            line-height: 37px;
            background: transparent;
            border: 1px solid #e2e2e2;
            border-radius: 20px;
            background-color: transparent;
            color: #c0c0c0;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.8571em;
            transition: 0s;

            .pin {
                margin-right: 8px;
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background-color: #eceff1;
                transition: 0s;
            }

            &.btn-filter-active {
                color: #fff;
                background-color: #294862;
                border-color: #294862;

                .pin {
                    background-color: #51bcda;
                    transition: 0.3s;
                }

                &.map-filter {
                    background-color: #fff;
                    color: #51bcda;
                    border-color: #51bcda;
                }

                transition: 0.3s;
            }

            &.map-filter {
                i {
                    padding-left: 6px;
                    padding-right: 6px;
                    font-size: 16px;
                }
            }
        }

        .search-container {
            display: flex;
            justify-content: flex-end;
            margin-right: 16px;

            .search-form {
                width: fit-content;

                .input-group,
                .form-group {
                    margin-bottom: 0;
                }
            }
        }

        .basic-multi-select {
            min-width: 240px;
            margin-right: 20px;

            &.finitalia {
                min-width: 180px;
            }

            .select__menu {
                z-index: 11;
                max-width: 260px;
                right: 0;

                .select__group {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            .select__control {
                border-radius: 20px;
                padding-left: 12px;

                &:hover {
                    cursor: pointer !important;
                }

                .select__multi-value {
                    padding-left: 2px;
                    padding-right: 0px;
                    margin-left: 4px;
                    margin-right: 8px;
                }
            }

            .select__control--is-focused {
                border-color: #51bcda;
                box-shadow: none;
                // box-shadow: 0 0 0 1px #51bcda;
            }

            .select__group {
                .select__group-heading {
                    color: #252422;
                    font-size: 100%;
                    font-weight: bold;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    padding-top: 10px;
                    padding-bottom: 6px;

                    .select-group-container {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                    }
                }

                .select__option {
                    cursor: pointer;
                    color: #252422;
                    padding: 8px 6px;

                    &:before {
                        content: "";
                        font-family: $icon-font-family;
                        margin-left: 16px;
                        margin-right: 22px;
                    }

                    &.select__option--is-focused {
                        background-color: transparent;
                    }

                    &.select__option--is-selected {
                        background-color: rgba(222, 235, 255, 0.5);

                        &:before {
                            content: "\f00c";
                            font-family: $icon-font-family;
                            margin-left: 12px;
                            margin-right: 12px;
                        }
                    }

                    &:hover {
                        background-color: rgba(222, 235, 255, 0.5);
                    }
                }
            }
        }
    }
}

.card-header.header-center {
    justify-content: center;
}

@media (max-width: 1024px) {
    .communications-page {
        .ReactTable .rt-table {
            overflow: auto;
        }

        &.content .card-title {
            margin-bottom: 8px;
        }

        .card-header.header-with-search,
        .card-header.header-with-corner-button {
            flex-direction: column;
            padding: 10px 10px 0;

            .search-form {
                min-width: 200px;
            }

            .header-items-container .filter-search {
                flex-wrap: wrap;

                & > div {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .ReactTable .-pagination,
    .ReactTable .rt-thead .rt-th,
    .ReactTable .rt-th,
    .ReactTable .rt-td {
        font-size: 13px;
    }

    .ReactTable .-pagination .-previous,
    .ReactTable .-pagination .-next {
        display: flex;
        align-items: center;

        button {
            width: 80%;
        }
    }

    .ReactTable .-pagination .-previous {
        justify-content: flex-start;
    }

    .ReactTable .-pagination .-next {
        justify-content: flex-end;
    }

    .ReactTable .-pagination .-btn {
        height: 32px;
    }
}

@media (max-width: 812px) {
    .communications-page .ReactTable .rt-table .rt-td.communication-statuses.communication-interactions {
        .interaction-list {
            font-size: 14px;

            li {
                margin-left: 10px;
                width: 24px;
                height: 24px;
                padding: 0px 7px;
            }
        }
    }
}

@media (max-width: 768px) {
    .communications-page {
        &.content .card-title {
            margin-top: 0;
            margin-bottom: 8px;
        }

        .card-header.header-with-search {
            padding-left: 16px;
        }

        .card-header.header-with-search,
        .card-header.header-with-corner-button {
            .header-items-container {
                flex-direction: column;
                align-items: flex-start;

                .download-all-list,
                .filter-search .basic-multi-select {
                    margin-bottom: 12px;
                }
            }
        }
    }

    .card .card-body {
        padding: 10px;
    }
}

@media (max-width: 620px) {
    .communications-page {
        .ReactTable .-pagination,
        .ReactTable .rt-thead .rt-th,
        .ReactTable .rt-th,
        .ReactTable .rt-td {
            font-size: 12px;
        }

        .ReactTable .-pagination {
            padding: 8px 0 4px 0;

            input {
                max-width: 40px;
            }

            .-center {
                flex: 1 1;
            }

            .-previous,
            .-next {
                flex: 0;

                button {
                    width: 75px;
                }
            }
        }
    }
}
