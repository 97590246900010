
.mfa-page-container {
    text-align: center;

    .card {
        @media screen and (min-width: 768px) {
            width: min-content;
        }
    }

    .footer {
        input {
            width: 30%
        }
    }
}

.title {
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: 600;
    text-align: center;
}