.modal.preview-interaction-container {
    &.phone-view .phone-preview .hermes-app-preview {
        background: url('./img/iphone_portrait_sized.png') no-repeat center;
        background-size: 423px 809px !important;
    }

    &.tablet-view .phone-preview .hermes-app-preview {
        background: url('./img/tablet_sized.png') no-repeat center;
        background-size: 800px 539px !important;

        .preview-mask {
            top: 0;
        }
    }

    &.desktop-view .phone-preview .hermes-app-preview {
        background: url('./img/mac_sized.png') no-repeat center !important;
        background-size: 950px 684px !important;

        .preview-mask {
            top: 0;
        }
    }

    &.full-screen-view .phone-preview .hermes-app-preview {
        background: none !important;
        width: 80vw;
        height: 98vh;

        .preview-mask {
            top: 0;
        }
    }
}


.modal-footer {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 550px;
    height: 100%;
    transform: translate3d(0%, 0, 0), width 0.351s ease;
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 0;
    height: 100vh;
}

/*Left*/
.modal.left.fade .modal-dialog {
    left: -320px;
    transition: opacity 0.351s linear, left 0.351s ease-out, width 0.351s ease;
}

.modal.left.fade.show .modal-dialog {
    left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
    right: -320px;
    transition: opacity 0.351s linear, right 0.351s ease-out, width 0.351s ease;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}


.modal-dialog.notes-modal {

    .modal-header {
        border-bottom: none;
    }

    .modal-body,
    .modal-footer {
        background-color: #fafafa;
    }

    .modal-footer {
        border-top: none;
    }

    .notes-text-area {
        background: rgba(255, 255, 255, .1);
        border: 0;
        border-radius: 4px;
        font-size: 16px;
        margin: 0;
        outline: 0;
        padding: 10px;
        width: 100%;
        min-height: 300px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        background-color: #e8eeef;
        color: #6d7982;
        -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
    }
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
}

.claim-interaction-modal {
    // width: 650px;

    .modal-body {
        position: relative;
    }

    .close-modal-btn {
        position: absolute;
        top: 15px;
        right: 25px;
        z-index: 2;

        .nc-icon {
            font-size: 30px;
        }
    }

    .close-modal-btn button:focus {
        outline: none;
    }

    .alert.alert-lock {
        position: absolute;
        top: -100px;
        left: 0;
        right: 0;
        z-index: 100;
        display: flex;
        transition: top 0.3s;

        &.support-locked {
            top: 0;
            padding: 12px 20px 8px 20px;
        }
    }

    .modal-content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

}

.claim-interaction-modal.modal.left.full-screen-view .modal-dialog,
.claim-interaction-modal.modal.right.full-screen-view .modal-dialog {
    width: 95vw;
    height: 100vh;
}

@media (max-width: 680px) {

    .modal.left .modal-dialog,
    .modal.right .modal-dialog {
        max-width: 500px;
        transition: max-width 0.351s ease;
    }

    .claim-interaction-modal.modal.left.phone-view .modal-dialog,
    .claim-interaction-modal.modal.right.phone-view .modal-dialog {
        width: 500px;
    }

    .claim-interaction-modal.modal.left.tablet-view .modal-dialog,
    .claim-interaction-modal.modal.right.tablet-view .modal-dialog {
        width: 1000px;
    }

    .claim-interaction-modal.modal.left.desktop-view .modal-dialog,
    .claim-interaction-modal.modal.right.desktop-view .modal-dialog {
        width: 1200px;
    }
}

@media (min-width: 680px) and (max-width: 1600px) {

    .modal.left .modal-dialog,
    .modal.right .modal-dialog {
        max-width: unset;
        transition: width 0.351s ease;
    }

    .claim-interaction-modal.modal.left.phone-view .modal-dialog,
    .claim-interaction-modal.modal.right.phone-view .modal-dialog {
        width: 550px;
    }

    .claim-interaction-modal.modal.left.tablet-view .modal-dialog,
    .claim-interaction-modal.modal.right.tablet-view .modal-dialog {
        width: 1000px;
    }

    .claim-interaction-modal.modal.left.desktop-view .modal-dialog,
    .claim-interaction-modal.modal.right.desktop-view .modal-dialog {
        width: 1200px;
    }
}

@media (min-width: 1600px) {

    .modal.left .modal-dialog,
    .modal.right .modal-dialog {
        max-width: unset;
        transition: width 0.351s ease;
    }

    .claim-interaction-modal.modal.left.phone-view .modal-dialog,
    .claim-interaction-modal.modal.right.phone-view .modal-dialog {
        width: 550px;
    }

    .claim-interaction-modal.modal.left.tablet-view .modal-dialog,
    .claim-interaction-modal.modal.right.tablet-view .modal-dialog {
        width: 1300px;
    }

    .claim-interaction-modal.modal.left.desktop-view .modal-dialog,
    .claim-interaction-modal.modal.right.desktop-view .modal-dialog {
        width: 1600px;
    }
}