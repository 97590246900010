.fixed-plugin {

  li.header-title{
    text-align: left;
  }

    .dropdown{
        .dropdown-menu{
          transform-origin: 0 0;
          padding: 16px;

          &:before{
            border-bottom: 16px solid rgba(0, 0, 0, 0.05);
            border-left: 16px solid rgba(0, 0, 0, 0);
            border-right: 16px solid rgba(0, 0, 0, 0);
            right: 12px;
            top: -12px;
          }

          &:after{
            border-bottom: 16px solid #FFFFFF;
            border-left: 16px solid rgba(0, 0, 0, 0);
            border-right: 16px solid rgba(0, 0, 0, 0);
            right: 12px;
            top: -11px;
          }

          &:before,
          &:after{
             content: "";
             display: inline-block;
             position: absolute;
             width: 16px;
          }
        }
    }
}

