.notifications-content {
    position: relative;

    div {

        .col-11.col-sm-4 {
            max-width: 0;
            width: 200px;
            display: inline-block;
            margin: 0px auto;
            position: absolute !important;
            transition: all 0.5s ease-in-out 0s;
            z-index: 1031;
            cursor: pointer;

            .alert-warning {
                margin-left: -100px;
                width: 180px;
                height: 30px;
                border-radius: 150px;
                padding: 0;
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

                .fa-arrow-up {
                    padding-top: 2px;
                    padding-left: 0px;
                    font-size: 18px;
                }

                button {
                    display: none;
                }

                span {
                    font-size: 14px;
                    padding-top: 5px;
                    padding-left: 40px;
                }

            }
        }

    }

}