.navbar {

  .container-fluid {
    padding-right: 35px;
    padding-left : 35px;
  }

  &.fixed-top {
    position: fixed;
  }

  &.navbar-absolute {
    padding-top   : 8px;
    padding-bottom: 8px;
  }

  &.navbar-dark {
    background-color: $black-color !important;
    box-shadow      : none;
    border-bottom   : 1px solid #ddd;

    a:not(.dropdown-item):not(.btn) {
      color: $white-color;

      &.disabled {
        opacity: .5;
        color  : $white-color;
      }
    }

    .button-bar {
      background: $white-color;
    }

    .nav-item .nav-link:not(.btn) {
      color: $white-color;
    }

    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):focus,
    .nav-item .nav-link:not(.btn):hover,
    .nav-item .nav-link:not(.btn):focus:hover,
    .nav-item .nav-link:not(.btn):active {
      color: $primary-color;
    }
  }

  .navbar-wrapper>.logo {
    display       : flex;
    flex-direction: row;
    align-items   : center;
  }

  .logo a.logo-mini {
    opacity           : 1;
    float             : left;
    text-align        : center;
    margin-left       : 10px;
    text-transform    : uppercase;
    padding           : 0;
    display           : block;
    white-space       : nowrap;
    font-size         : 1rem;
    color             : #FFFFFF;
    text-decoration   : none;
    font-weight       : 400;
    line-height       : 30px;
    overflow          : hidden;
    transition        : all 300ms ease 0s;
    margin-right      : 20px;

    .logo-img {
      height: 100%;
      width : 160px;
    }
  }

  .logo a.logo-normal {
    display        : block;
    opacity        : 1;
    padding        : 9px 0 8px;
    text-transform : uppercase;
    white-space    : nowrap;
    font-size      : 22px;
    color          : #FFFFFF;
    text-decoration: none;
    font-weight    : 400;
    line-height    : 30px;
    overflow       : hidden;
    letter-spacing : 1px;
    transform      : translate3d(0px, 0, 0);

    .spacer {
      display    : inline-block;
      margin-left: 4px;
    }
  }

  .navbar-list-wrapper {
    .nav-menu {
      color    : #FFFFFF;
      font-size: 14px;
      cursor   : pointer;

      .header-icon-label {
        margin-right: 15px;
      }

      .dropdown-toggle:after {
        margin-left: 8px;
      }
    }
  }

  form.search-form {
    margin-right: 25px;
  }

  form.search-form .input-group>input {
    width     : 180px;
    transition: width 0.3s ease;
  }

  form.search-form .input-group>input:focus {
    width: 280px;
  }

  form.search-form .input-group>input,
  form.search-form .input-group>input:active,
  form.search-form .input-group>input:focus {
    background-color: #FFFFFF;
  }

  form.search-form .input-group .input-group-append>.input-group-text,
  form.search-form .input-group .input-group-append>.input-group-text:active,
  form.search-form .input-group>input:focus+.input-group-append>.input-group-text {
    background-color: #FFFFFF;
  }
}

@media (max-width: 767px) {
  .navbar {
    .navbar-list-wrapper {
      .nav-menu {
        .header-icon-label {
          display: none;
        }

        i {
          font-size: 18px;
        }
      }
    }
  }

  .fixed-plugin .dropdown-menu {
    right: 30px;
  }
}